import { ContentColumn } from '../..'
import { HomeCareResidencyCostMix } from './HomeCareResidencyCostMix'
import { VolumeAgeGroup } from './VolumeAgeGroup'
import { VolumeDistribution } from './VolumeDistribution'
import { VolumeInsatsMix } from './VolumeInsatsMix'
import { VolumeProportionAgeGroup } from './VolumeProportionAgeGroup'
import { VolumeQualityMix } from './VolumeQualityMix'

export const benchmarkingVolume: ContentColumn[] = [
  {
    onlyRenderIfBenchmark: 'volume',
    content: (
      <VolumeDistribution
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Brukarvolymen mäts utifrån hur många som får hemtjänst eller särskilt boende per 100 invånare inom respektive ålderskategori.
            <br />
            <br />
            <b>Bakgrund</b>
            <br />
            Brukarvolymen är en delförklaring till höga kostnader.
            <br />
            <br />
            <b>Syfte</b>
            <br />
            Måttet beskriver om kommunen är frikostig eller restriktiv med insatser i förhållande till andra. Brukarantalet kan sägas beskriva hur hög tröskel respektive kommun har för insatser.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Uppdelningen i ålderskategorier är viktig för att kunna jämföra olika kommuner med olika demografi. Det beror på att behov av insatser från äldreomsorgen ökar kraftigt med åldern, med en
            ungefärlig dubblering vart femte år för särskilt boende.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Fördelning av volymer och kostnader inom äldreomsorgen',
  },
  {
    onlyRenderIfBenchmark: 'volumeAgeGroup',
    content: (
      <VolumeAgeGroup
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Brukarvolymen mäts utifrån hur många som får hemtjänst eller särskilt boende per 100 invånare inom respektive ålderskategori.
            <br />
            <br />
            <b>Bakgrund</b>
            <br />
            Brukarvolymen är en delförklaring till höga kostnader.
            <br />
            <br />
            <b>Syfte</b>
            <br />
            Måttet beskriver om kommunen är frikostig eller restriktiv med insatser i förhållande till andra. Brukarantalet kan sägas beskriva hur hög tröskel respektive kommun har för insatser.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Uppdelningen i ålderskategorier är viktig för att kunna jämföra olika kommuner med olika demografi. Det beror på att behov av insatser från äldreomsorgen ökar kraftigt med åldern, med en
            ungefärlig dubblering vart femte år för särskilt boende.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Andel med insatser från äldreomsorgen i respektive åldersgrupp',
  },

  {
    onlyRenderIfBenchmark: 'volumeProportionAgeGroup',
    content: (
      <VolumeProportionAgeGroup
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Brukarvolymen mäts utifrån hur många som får hemtjänst eller särskilt boende per 100 invånare inom respektive ålderskategori.
            <br />
            <br />
            <b>Bakgrund</b>
            <br />
            Brukarvolymen är en delförklaring till höga kostnader.
            <br />
            <br />
            <b>Syfte</b>
            <br />
            Måttet beskriver om kommunen är frikostig eller restriktiv med insatser i förhållande till andra. Brukarantalet kan sägas beskriva hur hög tröskel respektive kommun har för insatser.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Uppdelningen i ålderskategorier är viktig för att kunna jämföra olika kommuner med olika demografi. Det beror på att behov av insatser från äldreomsorgen ökar kraftigt med åldern, med en
            ungefärlig dubblering vart femte år för särskilt boende.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Fördelning mellan insatser i ordinärt resp. särskilt boende per åldersgrupp',
  },
  {
    onlyRenderIfBenchmark: 'volumeInsatsMix',
    content: (
      <VolumeInsatsMix
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Brukarvolymen mäts utifrån hur många som får hemtjänst eller särskilt boende per 100 invånare inom respektive ålderskategori.
            <br />
            <br />
            <b>Bakgrund</b>
            <br />
            Brukarvolymen är en delförklaring till höga kostnader.
            <br />
            <br />
            <b>Syfte</b>
            <br />
            Måttet beskriver om kommunen är frikostig eller restriktiv med insatser i förhållande till andra. Brukarantalet kan sägas beskriva hur hög tröskel respektive kommun har för insatser.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Uppdelningen i ålderskategorier är viktig för att kunna jämföra olika kommuner med olika demografi. Det beror på att behov av insatser från äldreomsorgen ökar kraftigt med åldern, med en
            ungefärlig dubblering vart femte år för särskilt boende.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Brukarvolym och insatsmix',
  },
  {
    onlyRenderIfBenchmark: 'volumeInsatsMix',
    content: (
      <HomeCareResidencyCostMix
        text={
          <>
            <b>Beskrivning</b>
            <br />
            Brukarvolymen mäts utifrån hur många som får hemtjänst eller särskilt boende per 100 invånare inom respektive ålderskategori.
            <br />
            <br />
            <b>Bakgrund</b>
            <br />
            Brukarvolymen är en delförklaring till höga kostnader.
            <br />
            <br />
            <b>Syfte</b>
            <br />
            Måttet beskriver om kommunen är frikostig eller restriktiv med insatser i förhållande till andra. Brukarantalet kan sägas beskriva hur hög tröskel respektive kommun har för insatser.
            <br />
            <br />
            <b>Tolkning</b>
            <br />
            Uppdelningen i ålderskategorier är viktig för att kunna jämföra olika kommuner med olika demografi. Det beror på att behov av insatser från äldreomsorgen ökar kraftigt med åldern, med en
            ungefärlig dubblering vart femte år för särskilt boende.
            <br />
          </>
        }
      />
    ),
    pageTitle: 'Insatsmix, kostnader',
  },
]
