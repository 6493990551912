import { Select, Spin } from 'antd'
import React, { ComponentProps, useEffect } from 'react'
import { Bar } from 'react-chartjs-2'
import { useMunicipalityName, useVolumeAgeGroupValues } from '../../../../api/benchmarking'
import BenchmarkingContentRow from '../../components/BenchmarkingContentRow'
import { getFontSize } from '../../util/fontSize'
import { BASELINE_COLOR, getComparisonColor } from '../../util/colors'
import { BenchmarkingLegend } from '../../components/BenchmarkingLegend'
import { BORDER_WIDTH } from '../../util/constants'
import { baseGraphOptions } from '../../util/baseGraphOptions'

export const VolumeAgeGroup = ({ text }: { text: React.ReactNode }) => {
  const { data, isLoading, mutate } = useVolumeAgeGroupValues()
  const { data: municipalityName, isLoading: isMunicipalityNameLoading } = useMunicipalityName()
  useEffect(() => {
    mutate({})
  }, [])

  if (isLoading || isMunicipalityNameLoading) {
    return <BenchmarkingContentRow graph={<Spin size="large" />} text={text} />
  }

  if (!data) {
    return <></>
  }

  const options: ComponentProps<typeof Bar>['options'] = {
    ...baseGraphOptions,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.datasetIndex === 0 ? municipalityName : context.dataset.label}: ${Math.round(context.parsed.y)}`
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: getFontSize('md'),
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: getFontSize('md'),
          },
          callback: (value) => `${value}%`,
        },
      },
    },
  }
  const graphData: ComponentProps<typeof Bar>['data'] = {
    labels: data.baselineValues.map((item) => item.ageRange),
    datasets: [
      {
        label: data.currentVariation.baseline?.name,
        data: data.baselineValues.map((item) => item.value),
        backgroundColor: BASELINE_COLOR.fill,
        borderColor: BASELINE_COLOR.border,
        borderWidth: BORDER_WIDTH,
      },
      {
        label: data.currentVariation.comparison?.name,
        data: data.comparisonValues.map((item) => item.value),
        backgroundColor: getComparisonColor(data.currentVariation.comparison?.grouping?.aggregation?.variant, data.currentVariation.comparison?.grouping?.aggregation?.quantifier).fill,
        borderColor: getComparisonColor(data.currentVariation.comparison?.grouping?.aggregation?.variant, data.currentVariation.comparison?.grouping?.aggregation?.quantifier).border,
        borderWidth: BORDER_WIDTH,
      },
    ],
  }

  const dataSourceSelector = (
    <Select
      value={data.currentDataSource}
      onChange={(value) => {
        mutate({ datasource: value, grouping: undefined })
      }}
      options={data.dataSources.map((ds) => ({
        label: ds,
        value: ds,
      }))}
      style={{ width: '100%' }}
    />
  )

  const comparisonSelector = (
    <Select
      value={data.currentGrouping}
      onChange={(value) => {
        mutate({ grouping: value, datasource: data.currentVariation.comparison?.datasource?.name })
      }}
      options={data.groupings.map((grouping) => ({
        label: grouping,
        value: grouping,
      }))}
      style={{ width: '100%' }}
    />
  )
  const legend = (
    <BenchmarkingLegend
      items={[
        { text: municipalityName, fillColor: BASELINE_COLOR.fill, borderColor: BASELINE_COLOR.border },
        {
          text: data.currentGrouping,
          fillColor: getComparisonColor(data.currentVariation.comparison?.grouping?.aggregation?.variant, data.currentVariation.comparison?.grouping?.aggregation?.quantifier).fill,
          borderColor: getComparisonColor(data.currentVariation.comparison?.grouping?.aggregation?.variant, data.currentVariation.comparison?.grouping?.aggregation?.quantifier).border,
        },
      ]}
    />
  )

  const graph: React.ReactNode = <Bar options={options} data={graphData} />
  const title: string = 'Andel med insatser från äldreomsorgen i respektive åldersgrupp'
  return <BenchmarkingContentRow graph={graph} dataSourceSelector={dataSourceSelector} comparisonSelector={comparisonSelector} text={text} title={title} legend={legend} />
}
